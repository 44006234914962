<template>
<v-row>
    <v-col cols="12" md="12">
        <s-crud no-border formPermanent title="Correlativo" height="auto" noFooter :config="this.config" @save="save($event)" add edit sortable @rowSelected="rowSelected($event)" searchInput>
            <template slot-scope="props">
                <v-container v-if="props.item != null" class="pb-0">
                    <v-row justify="center">
                        <v-col lg="12" md="12" class="s-col-form">
                            <s-text v-model="props.item.CacBussinesnName" label="Empresa" autofocus />
                        </v-col>
                        <v-col lg="6" md="6" class="s-col-form">
                            <s-text v-model="props.item.CacCorrelativeOriginal" label="Coc. Prod." :readonly="!$fun.isAdmin()" />
                        </v-col>
                        <v-col lg="6" md="6" class="s-col-form">
                            <s-text v-model="props.item.CacCorrelative" label="Coc. Prueba" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </s-crud>
    </v-col>
</v-row>
</template>

<script>
import _sCorrelative from "@/services/Accounting/ConCorrelativeService";
export default {
    components: {},
    data: () => ({
        config: {
            model: {
                CacID: "ID",
                CacBussinesnName: "string",
                CacCorrelativeOriginal: "string",
                CacCorrelative: "string"

            },

            service: _sCorrelative,
            headers: [{
                    text: "ID",
                    value: "CacID",
                    sorteable: false,
                },
                {
                    text: "Empresa",
                    value: "CacBussinesnName",
                    sorteable: false,
                },
                {
                    text: "Correlativo PROD.",
                    value: "CacCorrelativeOriginal",
                    sorteable: false,
                },
                {
                    text: "Correlativo DEMO",
                    value: "CacCorrelative",
                    sorteable: false,
                },
            ],
        },
    }),
    created(){
        
    },
    methods: {
        rowSelected(items) {
            
        },
        save(item) {
           
           item.save()
        },
    },
    computed: {},
};
</script>
